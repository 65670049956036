
.foaImg {
    position: relative;
}

.image {
    opacity: 1;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    bottom: 0px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer;
}

.foaImg:hover .image {
    opacity: 0.3;
}

.foaImg:hover .middle {
    opacity: 1;
}

/* .ecommerce-card.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
} */

.text-p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.responsive-image {
    width: 100%; /* Div genişliğine uyum sağlar */
    height: 100%; /* Div yüksekliğine uyum sağlar */
    object-fit: cover; /* Görüntüyü kırparak div boyutuna uyum sağlar */
    position: absolute; /* Konumlandırmayı kontrol etmek için */
    top: 0;
    left: 0;
}