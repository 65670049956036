@media only screen and (min-width: 767px) and (max-width: 1023px) {
    .card-congratulations{
        height: 50px !important;
    }
}

@media (max-width: 576px) {
    .card-congratulations{
        height: 300px !important;
    }

    .congratulations-logo-left {
        width: 12% !important;
    }
    .congratulations-logo-right {
        width: 8% !important;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px){
    .congratulations-logo-left {
        width: 100% !important;
    }
    .congratulations-logo-right {
        width: 60% !important;
    }
}